:root {
  --black: #1E1C1C;
  --white: #ffffff;
  --grey: grey;
  --darkgrey: rgb(71, 71, 71);
  --blue:rgb(0, 119, 255);
  --red:rgb(191, 0, 0);
}

body{
  background-color:var(--white);
}

a{
  transition:transform 0.5s;
  cursor: pointer;
  text-decoration: none;
}

h1{
color: var(--black)
}
h2{
  color: var(--black)
  }

h5{
  color: var(--black)
  }

p{
  color:var(--darkgrey);
}

a:hover{
transform:scale(0.95)
}

.navLinks{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-evenly;
  width:300px;
  margin: auto;
  font-size: 15px;
  
}

.grow{
transition: transform ease-in-out 0.3s;
}

.grow:hover{
  transform:scale(1.03)
}

.navLogo{
  width:150px;
}

.mainLogo{
  width:100px;
  margin-top:25px;
  margin-bottom:25px;
}

.navContact{
  width:150px;
  display:flex;
  justify-content:flex-end;
}

.navBar{
  display:flex;
  flex-direction:row;
  align-items:center;
  align-content: center;
  padding-left:30px;
  padding-right:30px;
}

.navLink{
  color:var(--black);
  text-decoration:none;
  
}

.navLink:hover{
  color:var(--grey);
  
}

.btn{
  display:flex;
  align-items:flex-end;
  width:fit-content;
padding: 6px 25px;
font-weight:600;
font-size:12px;
border-radius: 100px;
}

.roundedBtn{
  display:flex;
  align-items:flex-end;
  width:fit-content;
padding: 8px 35px;
font-weight:600;
font-size:17px;
border-radius: 100px;
margin:auto;
}

.CTAbtn{
  display:flex;
  align-items:flex-end;
  width:fit-content;
padding: 8px 35px;
font-weight:600;
font-size:17px;
border-radius: 100px;
}

.blackButton{
color: var(--white);
background-color: var(--black);
}

.whiteButton{
  color: var(--black);
  background-color: var(--white);
  }

.blueButton{
  color: var(--white);
  background-color: var(--blue);
  }

.heroSection{
  display:flex;
  max-width: 950px;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
}

.heroTitle{
  font-size:50px;
}

.CTASection{
  display:flex;
  max-width: 900px;
  height:500px;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
  
}

.CTAText{

display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-around;
max-width:400px;
background-color: white;
border-radius:10px;
padding-left:35px;
padding-right:35px;
padding-bottom:30px;
}

.philSection{
  display:flex;
  max-width: 900px;
  align-items: flex-start;
  justify-content: space-around;
  align-content: center;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
}
.docBody{
  background-color: #fff3e6;
  height:100%;
  padding-top:20px;
  padding-bottom:40px;
}

.docSection{
  max-width: 900px;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
  background-color:#fff3e6;
  text-align: center;
}

.philBox{
  
  display: flex;
  flex-direction: column;
  
  max-width:350px;
  
  border-radius:10px;
  padding-left:35px;
  padding-right:35px;
  }

.noMT{
  margin-top:0px;
}
  
.lineDivide{
  border-bottom:2px solid var(--black);
  width:60%;
  height:20px;
  
}


.heroImg{
  background-size:cover;
  background-position: 25% 75%;
  border-radius: 10px;
  height:500px;
}
.heroLeft{
  padding:10px;
  width:350px;
  background-color:var(--white);
  z-index: 1;
  border-radius: 10px;
  /* border:3px solid var(--black); */
  padding-left:40px;
  padding-right:40px;
}

.heroRight{
  padding:10px;
  width:500px;
  
}

.featureSection{
  display:flex;
  max-width: 900px;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
}

.featureSectionflip{
  display:flex;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.featureImg{
  background-size:cover;
  background-position: center;
  border-radius: 10px;
  
  height:400px;
}

.imgApp{
width:400px;
}

.featureImg300{
  background-size:cover;
  background-position: center;
  border-radius: 10px;
  /* width:315px; */
  height:310px;
}

.featureImg300wide{
  background-size:cover;
  background-position: center;
  border-radius: 10px;
  /* width:400px; */
  height:350px;
}

.featureImg450{
  background-size:cover;
  background-position: center;
  border-radius: 10px;
  
  height:450px;
}



.featureLeft{
  padding:10px;
  width:400px;
}

.featureRight{
  padding:10px;
  width:400px;
}

.alignSideDiv{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content:center;
  align-content: center;
}

.alignSideDivLeft{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content:flex-start;
  align-content: center;
}

.mt30{
  margin-top:30px;
}

.mt50{
  margin-top:50px;
}

.mt80{
  margin-top:80px !important;
}
.copyrightText{
font-size:10px;
color:rgb(197, 197, 197);
font-weight:500;

}

.footerBar{
  display:flex;
  align-items: center;
  justify-content:center;
  align-content:center;
  

}

.bgImage{
  background-size:cover;
  background-position: center;
  
  border-radius:10px;
  margin:20px;
}
.herobgImage{
  background-size:cover;
  background-position: 45% 80%;
  padding:40px;
  border-radius:10px;
  margin:20px;
}






.heroAppsSection{
  display:flex;
  justify-content:center;
  flex-direction: column;
  align-items: center;
}

.heroAppsText{
  display:flex;
  justify-content:center;
  flex-direction: column;
  align-items: center;
  background-color:var(--white);
  border-radius:10px;
  padding:20px 40px 20px 40px;
  text-align: center;
  max-width:400px;
}

.featureAppsText{
  display:flex;
  justify-content:flex-start;
  flex-direction: column;
  align-items: flex-start;
  background-color:var(--white);
  border-radius:10px;
  padding:20px 40px 20px 40px;
  
  max-width:400px;
}


.appGridSection{
  display:flex;
  max-width: 900px;
  align-items: flex-start;
  justify-content: space-around;
  align-content: center;
  margin: auto;
  flex-wrap: wrap;
  margin-top:40px;
}

.appGridBox{
  display: flex;
  flex-direction: row-reverse;
  max-width:350px;
  background-color: white;
  border-radius:10px;
  padding-left:35px;
  padding-right:35px;
  }

  .appGridBoxLeft{
    display: flex;
    flex-direction: column;
    max-width:350px;
    background-color: white;
    border-radius:10px;
    padding-left:10px;
    padding-right:10px;
    }

    .appGridBoxRight{
      display: flex;
      flex-direction: column;
      max-width:350px;
      background-color: white;
      border-radius:10px;
      padding-left:10px;
      padding-right:10px;
      }

      .appGridImg{
        width:110px;
        height:110px;
        background-size:cover;
        background-position: center;
        border-radius:10px;

      }


      .heroIntegrationsSection{
        display:flex;
        justify-content:center;
        flex-direction: column;
        align-items: center;
      }

      


      
      .heroIntegrationsText{
        display:flex;
        justify-content:center;
        flex-direction: column;
        align-items: center;
        background-color:hsla(0, 0%, 100%, 0.844);
        
        border-radius:10px;
        padding:0px;
        max-width:400px;
        z-index: 1;
      }

      .integrationsImage{
        background-size:cover;
        background-position: center;
        width:100%;
        height:500px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .integrationsHowSection{
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin:auto;
      justify-content: center;
      }



      .integrationsHowBox{
        border:2px solid var(--blue);
        border-radius: 10px;
        width:250px;
        padding:20px;
        margin:10px;
      }

      .whatIntegrationsSection{
        display:flex;
        justify-content:center;
        flex-direction: column;
        align-items: flex-start;
        max-width:900px;
        margin:auto;
        
      }

      .whatinthead{
        text-align: left;
        padding:20px;
        padding-bottom:0px;
      }

      .pwhattext{
        padding:20px;
        padding-top:0px;
        max-width:600px;
      }

      .reportHowBox{
        border:2px solid black;
        border-radius: 10px;
        max-width:450px;
        padding:20px;
        margin:10px;

      }

      .optionsGrid{

display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: flex-start;
margin-left: -5px;
      }

      .optionItem{
display:flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: fit-content;
border: 1px solid var(--blue);
border-radius: 100px;
padding-left: 8px;
padding-right: 8px;
padding-bottom: 5px;
padding-top: 5px;
margin: 5px;
color:var(--blue);
}

.optionItemNOBORDER{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
 
  border-radius: 100px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin: 5px;
  
  }

.mtmbNO{
  margin-top: 0px;
  margin-bottom:0px;
}

      


      .appIconGrid{
        display:flex;
        align-items: center;
        
      }

      .appIcon{
        width:40px;
        padding:10px;
        
      }



      .appImg{
        width:60px; 
        margin:5px;
        border-radius:5px;
      }

      .appText{
font-weight:500;
margin:5px;
font-size:16px;
      }

      .autoApp{
        display:flex;
        width:275px;
        align-items: center;
        padding:10px;
        border-radius: 10px;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.18));
        margin:15px;
      }

      .autoAppGrid{
        display:flex;
        flex-wrap: wrap;
        margin:auto;
        max-width: 1000px;
      }

/* THIS IS THE STYLING SECTION */



      .bgPink{
        background-color:rgb(255, 69, 100);
      }

      .bgBlue{
        background-color:var(--blue);
      }

      .linGradBlue{
        background-image: linear-gradient(to bottom right, rgb(0, 89, 255), rgb(25, 64, 84));
      }

      .linGradPurpleBlueOrange{
        background-image: linear-gradient(to bottom right, rgb(162, 2, 231), rgb(239, 167, 0),rgb(255, 30, 30));
      }

      .linGradOrange{
        background-image: linear-gradient(to bottom right, rgb(255, 139, 139), rgb(232, 60, 8));
      }

      .zapbeige{
        background-color:#fff3e6;
      }

      .white{
        color:var(--white);
      }

      .lightGR{
        color:rgb(162, 162, 162);
      }

      .textCenter{
        text-align: center;
      }



      .heroFeatureSection{
        display:flex;
        max-width: 900px;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        flex-wrap: wrap;
       
      }
      
      .heroFeatureSectionflip{
        display:flex;
        max-width: 900px;
        align-items: center;
        justify-content: center;
        margin: auto;
        flex-wrap: wrap;
        
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .bgColorbeigey{
        background-color: #f8f9f3;
      }

      .pl10{
        padding-left:8px;
      }

  .br12{
    filter: brightness(1.2);
  }

  .fliph{
    transform: scaleX(-1);
  }

  .c7bg{
    /* background: linear-gradient(15deg, #30477a,#1764ad,#014b5a); */
    /* border: 3px solid var(--blue); */
  }

  .featureappbg{
    background: linear-gradient(45deg,#039e53, #1328ad, #378b9e);
    border-radius: 10px;
    
  }

  .whiteText{
    color:white;
  }

  .lightp{
    color:rgb(232, 232, 232);
  }


.docLogoImg{
  width:300px;
}

.subheader{
  color:rgb(69, 69, 69);
  font-weight:500;
}

.docScreenshot{
  border-radius:10px;
  max-width:90%;
  margin:10px;
}

.docStep{
  margin-top:100px;
  margin-bottom:100px;
  width:100%;
}

.shadow{
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.368));
}

.iconLogo{
  width:30px;
  margin-top:25px;
  margin-bottom:25px;
}

.hideWhenLarge{
  display:None;
}

@media only screen and (max-width: 700px) {
  
  .hideWhenSmall{
    display:none;
  }

  .hideWhenLarge{
    display:block;
  }

  .featureSectionflip{
    justify-content: center;
  }

  .featureSection{
    justify-content: center;
  }

  .navBar{
    padding-left:5px;
    padding-right:5px;
  }

  .navLink{
    margin:10px;
  }

  .mailButtonSmall{
    padding:6px;
    border-radius:5px;
  }

  .imgApp{
    width:300px;
    border-radius: 10px;
  }

  .herobgImage{
    padding:10px;
  }

  .featureLeft{
    padding:30px;
  }

  .CTAText{
    background-color:#ffffffe6;
    border-radius:0px;
  }
}

